<template>
  <div class="error-page">
    <div class="error-page__body">
      <h1 class="error-page__body--header">
        404
      </h1>
      <h3 class="error-page__body--content">
        PAGE NOT FOUND
      </h3>
      <p>Looks like you've followed a broken link or entered a URL that doesn't exist on this portal.</p>
      <div class="error-page__body--separate" />
      <router-link
        class="error-page__body--action"
        to="/"
      >
        <font-awesome-icon icon="home" />
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Page404',
    data() {
      return {

      }
    }
  }
</script>
