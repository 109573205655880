// import '@babel/polyfill';
import Vue from 'vue';
import {BootstrapVue, BIcon, BIconThreeDots} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import VueI18n from 'vue-i18n';
import {library} from '@fortawesome/fontawesome-svg-core';
import VueTagsInput from '@johmun/vue-tags-input';

import {
  faAngleDown,
  faAngleUp,
  faCartPlus,
  faCheck,
  faPlayCircle,
  faPauseCircle,
  faCameraRetro,
  faCog,
  faCube,
  faDownload,
  faEnvelope,
  faFilePdf,
  faHome,
  faLock,
  faMinusCircle,
  faPencilAlt,
  faPlusCircle,
  faPowerOff,
  faPrint,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faTimes,
  faTrashAlt,
  faUserCircle,
  faUndo,
  faRedo,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faAngleDown,
  faAngleUp,
  faCartPlus,
  faCheck,
  faCameraRetro,
  faCog,
  faCube,
  faDownload,
  faEnvelope,
  faFacebookSquare,
  faFilePdf,
  faHome,
  faInstagram,
  faLock,
  faMinusCircle,
  faPauseCircle,
  faPencilAlt,
  faPlayCircle,
  faPlusCircle,
  faPowerOff,
  faPrint,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faTimes,
  faTrashAlt,
  faUserCircle,
  faUndo,
  faRedo,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faQuestion,
);

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import {store} from './store/store';
import 'vue-select/dist/vue-select.css';
import './assets/scss/custom.scss';
import './registerServiceWorker'
import messages from './lang';


Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-select', vSelect);
Vue.component('vue-tags-input', VueTagsInput);
Vue.component('BIcon', BIcon)
Vue.component('BIconThreeDots', BIconThreeDots)

Vue.use(BootstrapVue);
Vue.use(Notifications, {velocity});
Vue.use(VueI18n);

Vue.config.productionTip = false;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "en", // set locale
  messages, // set locale messages
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
