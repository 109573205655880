/**
 * Settings Module
 */
import axios from "axios";

const state = {
	selectedLocale: {
    name: "English",
    icon: "en",
    locale: "en"
  },
  addSurcharge: {
    status: 0,
  },
  isMaintenanceNow: {
    status: 0,
  },
  notifications: null,
};

// getters
const getters = {
	selectedLocale: state => {
		return state.selectedLocale;
	},
  addSurcharge: state => {
    return state.addSurcharge.status;
  },
  isMaintenanceNow: state => {
    return state.isMaintenanceNow.status;
  },
  notifications: state => {
    return state.notifications;
  },
};

// actions
const actions = {
  /**
   * Collect the system's setting such as maintenance, 3D viewer status.
   */
  async getSettingsData(context) {
    await axios
      .get(`${process.env.VUE_APP_API_HOST}/api/public/settings`)
      .then(res => {
        context.commit('setSettingsData', res.data.settings);
      });
  },
  async getNotifications(context) {
    await axios
      .get(`${process.env.VUE_APP_API_HOST}/api/public/notifications`)
      .then(res => {
        context.commit('setNotifications', res.data.notifications);
      });
  },
};

// mutations
const mutations = {
  setSettingsData(state, settings) {
    if (settings['add_surcharge']) {
      state.addSurcharge = settings['add_surcharge'];
    }

    if (settings['is_maintenance_now']) {
      state.isMaintenanceNow = settings['is_maintenance_now'];
    }
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
};

export default {
	state,
	getters,
	actions,
	mutations
}
