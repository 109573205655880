<template>
  <h1>500 Page</h1>
</template>

<script>
  export default {
    name: 'Page500',
    data() {
      return {

      }
    }
  }
</script>
