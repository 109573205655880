/**
 * Cart Module
 */

import axios from "axios";
import Vue from "vue";
import {useLocalStorage} from '@vueuse/core';

const state = {
  cart: useLocalStorage('cart', []),
};

// getters
const getters = {
  getItems: state => {
    state.cart.map((item, index) => {
      if (item.order_id === undefined) {
        state.cart.splice(index, 1);
      }
    });

    return state.cart;
  },
  getItemsCount: state => {
    return state.cart.reduce((accumulator, item) => accumulator + item.qty, 0);
  },
};

// actions
const actions = {
  addToCart({commit}, item) {
    commit('addToCart', item);
  },
  addOrderImage({commit}, data) {
    commit('addOrderImage', data);
  },
  changeItemQty({commit}, data) {
    commit('changeItemQty', data);
  },
  removeFromCart({commit}, id) {
    commit('removeFromCart', id);
  },
  removeAllFromCart({commit}) {
    commit('removeAllCart');
  },
};

// mutations
const mutations = {
  addToCart(state, item) {
    state.cart.unshift(item);
    // if (state.cart.length === 0) {
    // } else {
    //   let isSameProduct = false;
    //   state.cart.map((c) => {
    //     if (c.description === item.description) {
    //       isSameProduct = true;
    //       c.qty = Number(c.qty) + Number(item.qty);
    //     }
    //   });
    //   if (!isSameProduct) state.cart.push(item);
    // }
  },
  addOrderImage(state, data) {
    state.cart[0] = {
      ...state.cart[0],
      image: data.image,
      site: 'Public',
    };
    // Add order item to orders_history
    axios
      .post(`${process.env.VUE_APP_API_HOST}/api/public/orders-history`, state.cart[0])
      .then(res => {
        Vue.notify({
          group: 'product',
          type: 'success',
          text: res.data.message,
        });
      })
      .catch(err => {
        console.log(`store | modules | cart | ${err}`);
      });
  },
  changeItemQty(state, data) {
    let qty = Number(state.cart[data.id].qty);
    qty += Number(data.qty);
    state.cart[data.id].qty = qty;
    if (state.cart[data.id].qty === 0) {
      state.cart.splice(data.id, 1);
    }
  },
  removeFromCart(state, id) {
    state.cart.splice(id, 1);
  },
  removeAllCart(state) {
    state.cart = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
}
