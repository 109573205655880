<template>
  <div class="col-lg-4 dp-right-panel">
    <div class="row">
      <!-- Currently Building -->
      <div class="col-12 dp-currently-building">
        <div class="dp-currently-building-wrapper">
          <h2>{{ $t('message.detailPanelTitle') }}</h2>
          <h3 v-if="options.length > 0">
            {{ options[0].V }}
          </h3>
          <h4>
            {{ description(1) + description(2) + description(3) }}
          </h4>
          <div
            v-for="(option, index) in options"
            :key="index"
          >
            <dl
              v-if="index > 0 && option.V!=='No Finish' && option.V!=='No Fabric'"
              class="row"
            >
              <dt
                v-if="(options[index - 1].DF === 'STN' || options[index - 1].DF === 'PNT') && (isShowOrder || isSaveBtn)"
                class="col-sm-3 dp-label"
              >
                {{ getFinishLabel() }}
              </dt>
              <dt
                v-else
                class="col-sm-3 dp-label"
              >
                {{ getLabel(option.label) }}
              </dt>
              <dd
                class="col-sm-9 dp-value"
                :class="{'leaders': option['P']}"
              >
                <span class="item">
                  {{ option.V }}
                </span>
              </dd>
            </dl>
          </div>
          <div class="row dp-add-to-order-wrapper">
            <div class="col-sm-3 dp-qty">
              <span
                v-show="isShowOrder"
                class="qty-label"
              >Qty:</span>
              <label>
                <input
                  v-show="isShowOrder"
                  v-model="qty"
                  type="text"
                  class="form-control qty-value"
                >
              </label>
            </div>
            <div class="col-sm-4 dp-button">
              <button
                v-show="isShowOrder"
                class="btn btn-green btn-block"
                @click="add"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- / Currently Building -->
      <!-- Order Details -->
      <div class="col-12 dp-order-details">
        <div class="dp-order-details-wrapper">
          <div class="row order-details-header">
            <div class="col-7 order-details-title">
              <h2>{{ $t('message.orderDetails') }}</h2>
            </div>
            <div class="col-sm order-details-buttons">
              <!--<span
                class="order-tooltip"
                @click="showEmailModal = true"
              >
                <b-icon
                  v-if="isSendingMail"
                  icon="three-dots"
                  animation="cylon"
                  font-scale="1"
                />
                <font-awesome-icon icon="envelope" />
                <span class="order-tooltip-text">{{ $t('message.emailQuote') }}</span>
              </span>-->
              <span
                class="order-tooltip"
                @click="showOrderModal('Print')"
              >
                <font-awesome-icon icon="print" />
                <span class="order-tooltip-text">{{ $t('message.printQuote') }}</span>
              </span>
              <span
                class="order-tooltip"
                @click="showOrderModal('Download PDF')"
              >
                <font-awesome-icon icon="file-pdf" />
                <span class="order-tooltip-text">{{ $t('message.pdfQuote') }}</span>
              </span>
              <!--<span @click="showOrderModal('Download CSV')"><font-awesome-icon icon="download" /></span>-->
            </div>
          </div>
          <div class="items-list">
            <div
              v-if="getItems.length > 0"
              class="delete-all"
            >
              <span @click="removeAllCart">{{ $t('message.deleteAll') }}</span>
            </div>
            <!-- Single Item -->
            <div
              v-for="(item, index) in getItems"
              :key="index"
              class="single-item"
            >
              <dl :class="(isSaveBtn && index === editItemIndex) ? 'row twinkle-hint' : 'row'">
                <dt class="col-9 dp-value leaders">
                  <span class="item">
                    <span class="qty">{{ item.qty }}x</span> {{ item.options[0].V }}
                  </span>
                </dt>
                <dd class="col-3 delete">
                  <span
                    class="expander-icon"
                    @click="changeQty(index, 1)"
                  >
                    <font-awesome-icon icon="plus-circle" />
                  </span>
                  <span
                    class="expander-icon"
                    @click="changeQty(index, -1)"
                  >
                    <font-awesome-icon icon="minus-circle" />
                  </span>
                  <span
                    @click="editFromCart(index)"
                  >
                    <font-awesome-icon icon="pencil-alt" />
                  </span>
                  <span
                    style="padding-right: 5px"
                    @click="removeFromCart(index)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                </dd>
              </dl>
              <div class="options-list-wrapper">
                <div
                  :aria-controls="`collapse-${index}`"
                  class="expander-link"
                  @click="onToggleCollapse(index)"
                >
                  {{ item.options[1].V }} <br> {{ item.description }}
                  <div class="expander-icon">
                    <font-awesome-icon
                      v-if="!showCollapse || collapseIndex !== index"
                      icon="angle-down"
                    />
                    <font-awesome-icon
                      v-if="showCollapse && collapseIndex === index"
                      icon="angle-up"
                    />
                  </div>
                </div>

                <b-collapse
                  :id="`collapse-${index}`"
                  v-model="showCollapse"
                  class="mt-2"
                >
                  <ul
                    v-if="collapseIndex===index"
                    class="options-list"
                  >
                    <li
                      v-for="(option, i) in item.options"
                      :key="i"
                    >
                      <template
                        v-if="option.V!=='No Finish'"
                      >
                        {{ option.V }}
                      </template>
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </div>
            <!-- / Single Item -->
          </div>
          <div
            v-if="getItems.length === 0"
            class="dp-instructions"
          >
            <div class="row">
              <div class="col-6 col-xl-3 single-step">
                <div class="row">
                  <div class="col-12 px-1">
                    <span class="step">STEP 1</span>
                  </div>
                  <div class="col-4 px-1">
                    <img src="img/step-1.png" alt="Step 1" height="48" width="48"/>
                  </div>
                  <div class="col-8 px-1">
                    Choose your furniture
                  </div>
                </div>
              </div>

              <div class="col-6 col-xl-3 single-step">
                <div class="row">
                  <div class="col-12 px-1">
                    <span class="step">STEP 2</span>
                  </div>
                  <div class="col-4 px-1">
                    <img src="img/step-2.png" alt="Step 2" width="48" height="48"/>
                  </div>
                  <div class="col-8 px-1">
                    Complete your design
                  </div>
                </div>
              </div>

              <div class="col-6 col-xl-3 single-step">
                <div class="row">
                  <div class="col-12 px-1">
                    <span class="step">STEP 3</span>
                  </div>
                  <div class="col-4 px-1">
                    <img src="img/step-3.png" alt="Step 3" width="48" height="48"/>
                  </div>
                  <div class="col-8 px-1">
                    Save your furniture
                  </div>
                </div>
              </div>

              <div class="col-6 col-xl-3 single-step">
                <div class="row">
                  <div class="col-12 px-1">
                    <span class="step">STEP 4</span>
                  </div>
                  <div class="col-4 px-1">
                    <img src="img/step-4.png" alt="Step 4" width="48" height="48"/>
                  </div>
                  <div class="col-8 px-1">
                    Request info from retailer
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            :disabled="getItems.length===0"
            class="dp-button my-3 btn btn-green btn-block"
            @click="isShowGTRSuite = true"
          >
            WHERE TO BUY
          </button>
          <!-- Print Preview -->
          <div
            id="preview-print"
            ref="print"
          >
            <table
              style="width: 100%;"
              class="logo-header"
            >
              <tbody>
              <tr>
                <td style="width: 40%;">
                  <img
                    alt=""
                    class="print-logo"
                    :src="PrintLogo"
                    style="width: 250px; height: auto;"
                  >
                </td>
                <td
                  style="text-align: right; vertical-align: bottom; width: 60%;"
                >
                  <hr>
                  <p
                    v-if="order.customerName"
                    style="font-size: 0.8rem;font-weight: bold;padding-right: 10px;line-height: 3px"
                  >
                    {{ order.customerName }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
            <br>
            <table
              border="1"
              cellspacing="0"
              class="items"
              style="text-align: center; width: 100%; border: 1px solid rgb(240, 240, 240);"
            >
              <thead style="background-color: rgb(51,51,51);color: #ffffff">
              <td style="width: 25%; padding: 10px;">
                <strong>PREVIEW</strong>
              </td>
              <td style="width: 15%; padding: 10px;">
                <strong>QTY</strong>
              </td>
              <td style="width: 60%; padding: 10px;">
                <strong>DESCRIPTION</strong>
              </td>
              </thead>
              <tbody
                v-for="(item, index) in getItems"
                :key="index"
              >
              <tr
                class="product-header"
              >
                <td
                  style="width: 25%; border: 1px solid rgb(240, 240, 240);"
                  :rowspan="item.options.length + 2"
                >
                  <img
                    v-if="item.image"
                    :src="item.image"
                    alt="preview"
                    style="width: 100%; max-width: 100%; height: auto"
                  >
                </td>
                <td
                  style="vertical-align: top; padding: 5px; border: 1px solid rgb(240, 240, 240);"
                  :rowspan="item.options.length + 2"
                >
                  <strong>{{ item.qty }}</strong>
                </td>
                <td style="text-align: left; padding: 5px; border: 1px solid rgb(240, 240, 240);">
                  <strong>{{ item.options[0].V }} ({{ item.description }})</strong>
                </td>
              </tr>
              <tr
                v-for="(option, i) in item.options"
                :key="i"
                class="single-item"
              >
                <template
                  v-if="i > 0 && option.V!=='No Finish'"
                >
                  <td style="text-align: left; padding: 5px; border: 1px solid rgb(240, 240, 240);">
                    {{ option.V }}
                  </td>
                </template>
              </tr>
              <tr
                v-if="item.note"
                class="single-item"
              >
                <td
                  colspan="2"
                  style="border: 1px solid rgb(240, 240, 240); text-align: left;"
                  v-text="`Note: ${item.note}`"
                />
              </tr>
              </tbody>
            </table>

            <div style="margin-top: 30px; border-top: solid 1px #000; width: 100%">
              <span
                style="font-size: .65rem; padding-top: 20px;"
                v-html="$t('message.disclaimer')"
              />
            </div>
          </div>
          <!-- / Print Preview -->
        </div>
      </div>
      <!-- / Order Details -->
    </div>

    <!-- Similar chair modal -->
    <b-modal
      ref="chair-modal"
      v-model="showChairModal"
      centered
      hide-header-close
      hide-footer
    >
      <template
        v-slot:modal-header="{
          // eslint-disable-next-line vue/no-unused-vars
          close
        }"
      >
        <h5 class="modal-title">
          {{ `Would you like to add matching ${chairType} chair?` }}
        </h5>
        <div
          class="expander-icon"
          style="font-size: 24px; cursor: pointer;"
          @click="onModalCollapse"
        >
          <font-awesome-icon
            v-if="!modalCollapse"
            icon="angle-down"
          />
          <font-awesome-icon
            v-if="modalCollapse"
            icon="angle-up"
          />
        </div>
      </template>

      <div class="dp-chair-modal">
        <div
          v-for="(option, index) in similar"
          :key="index"
          class="dp-chair-modal__expand px-3"
        >
          <template v-if="modalCollapse">
            <dl
              v-if="index > 0 && option.V!=='No Finish'"
              class="row"
            >
              <dt class="col-sm-3 dp-label">
                {{ getLabel(option.label) }}
              </dt>
              <dd
                class="col-sm-9 dp-value"
                :class="{'leaders': option['P']}"
              >
                <span class="item">{{ option.V }}</span>
              </dd>
            </dl>
          </template>
        </div>

        <div class="row dp-chair-modal__footer">
          <div class="col-6">
            <div class="dp-qty">
              <span class="qty-label">Qty:</span>
              <label>
                <input
                  v-model="similarQty"
                  type="text"
                  class="form-control qty-value"
                >
              </label>
            </div>
          </div>
          <div class="col-6 text-right">
            <b-button
              class="mr-2"
              variant="success"
              @click="addSimilar"
            >
              YES
            </b-button>
            <b-button
              variant="danger"
              @click="closeChairModal"
            >
              NO
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- / Similar chair modal -->

    <!-- Email modal -->
    <b-modal
      v-model="showEmailModal"
      centered
      hide-header
      hide-footer
    >
      <v-overlay
        :value="isSendingMail"
        absolute
      >
        <b-icon
          icon="three-dots"
          animation="cylon"
          font-scale="4"
        />
      </v-overlay>
      <label class="dp-form-label">
        <span>{{ $t('message.sendTo') }}</span>
        <vue-tags-input
          v-model="tag"
          :tags="toEmailAddress"
          :add-on-key="[13, ',', ';']"
          placeholder=""
          @tags-changed="newTags => toEmailAddress = newTags"
        />
      </label>
      <p class="dp-form-comment">
        {{ $t('message.sendToLabel') }}
      </p>
      <label class="dp-form-label">
        <span>{{ $t('message.customerName') }}</span>
        <input
          v-model="order.customerName"
          type="text"
          class="form-control"
        >
      </label>
      <hr>
      <div class="text-right">
        <b-button
          class="mr-2"
          variant="danger"
          @click="showEmailModal = false"
        >
          CANCEL
        </b-button>
        <b-button
          variant="success"
          :disabled="!toEmailAddress || toEmailAddress.length === 0"
          @click="onEmail"
        >
          SEND
        </b-button>
      </div>
    </b-modal>
    <!-- / Email modal -->

    <!--  Order modal  -->
    <b-modal
      v-model="isShowOrderModal"
      title=""
      centered
      hide-footer
    >
      <v-overlay
        :value="isAddOrder"
        absolute
      >
        <b-icon
          icon="three-dots"
          animation="cylon"
          font-scale="4"
        />
      </v-overlay>
      <div class="row text-center">
        <label class="dp-form-label">
          <span>{{ $t('message.customerName') }}</span>
          <input
            v-model="order.customerName"
            type="text"
            class="form-control"
          >
        </label>
        <div class="col-12 text-right">
          <b-button
            class="mr-2"
            variant="info"
            @click="isShowOrderModal = false"
          >
            Cancel
          </b-button>
          <b-button
            v-if="isShowOrderAction === 'Print'"
            variant="success"
            @click="onPrint()"
          >
            {{ isShowOrderAction }}
          </b-button>
          <b-button
            v-if="isShowOrderAction === 'Download PDF'"
            variant="success"
            @click="onPdfDownload()"
          >
            {{ isShowOrderAction }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- / Order modal  -->

    <!--  GTR Suite modal  -->
    <div
      v-show="isShowGTRSuite"
      id="gti-suite-modal"
      class="gti-suite-modal"
    >
      <div class="modal-content">
        <span
          class="close"
          @click="isShowGTRSuite = false"
        >&times;</span>
        <div class="row text-center" style="padding: 15px">
          <!-- GTR Lead-Gen Widget - Start -->
          <gtr-form-widget
            :additional-data=additionalData
            :skus="skus"
            color="#83cb00"
            dense="true"
            flat="true"
            font="Nunito Sans"
            form-id="3a7f3d56-1b4b-4b5d-a204-0aaacc719140"
            locale="en"
            outlined="true"
            style="width: 100%"
          ></gtr-form-widget>
          <!-- GTR Lead-Gen Widget - End -->
        </div>
      </div>
    </div>
    <!-- / GTR Suite modal  -->
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import collections from '../../store/collections';
import {PrintLogo} from '../../constants/PrintLogo';

const timeNow = new Date();
/**
 * Detail Component
 */
export default {
  name: 'Details',
  props: {
    /**
     * Toggle the item's options on cart
     * `true` or `false`
     */
    isCollapsed: Boolean,
    /**
     * Toggle the similar product's dialog on chairs product
     * `true` or `false`
     */
    isDialog: Boolean,
    /**
     * Set `true` at edit mode
     */
    isEditMode: Boolean,
    /**
     * Show `Save` button at edit mode
     * `true` or `false`
     */
    isSaveBtn: Boolean,
    /**
     * Toggle the order button on details panel
     * `true` or `false`
     */
    isShowOrder: Boolean,
    /**
     * The product's type
     * `tables, chairs, barstool, benches, case`
     */
    productLine: {
      type: String,
      default() {
        return '';
      }
    },
    /**
     * The similar product on chair line
     */
    chairSimilar: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Dealer's note
     */
    note: {
      type: String,
      default() {
        return '';
      }
    },
  },
  data() {
    return {
      PrintLogo: PrintLogo.base64Image,
      additionalData: [],
      collapseIndex: 0,
      editItemIndex: null,
      isAddOrder: false,
      isSendingMail: false,
      isShowGTRSuite: false,
      isShowOrderModal: false,
      isShowOrderAction: '',
      modalCollapse: false,
      qty: 1,
      options: [],
      orderEnabled: false,
      printContent: '',
      selectedMode: '',
      showCollapse: false,
      showChairModal: false,
      showEmailModal: false,
      similar: [],
      similarQty: 1,
      skus: '',
      tag: '',
      toEmailAddress: [],
      order: {
        customerName: '',
        // ORDER_LINE: '',
        // SEQUENCE: '',
      },
      orderImages: [],
    };
  },
  components: {
    'gtr-form-widget': null,
  },
  computed: {
    ...mapGetters([
      'getItems',
      'getItemsCount'
    ]),
    chairType() {
      return this.similar.reduce(
        (type, option) =>
          option['V'].toUpperCase() === 'ARM' || option['V'].toUpperCase() === 'SIDE'
            ? option['V']
            : type,
        ''
      );
    },
    internals() {
      return this.options.reduce(
        (parts, option) => parts + (option['PF'] !== undefined ? option['PF'] : ''),
        ''
      );
    },
    product() {
      return this.options.reduce(
        (code, option) =>
          code === '' && option['PL'] !== undefined && option['PL'] !== ''
            ? option['PL']
            : code,
        ''
      );
    },
    qtyItems() {
      return Array.from({length: 10}, (v, k) => k + 1);
    },
  },
  mounted() {
    this.updatePreviewContent();
    const GTRLeadWidgetScript = document.createElement("script");
    GTRLeadWidgetScript.setAttribute(
      "src",
      "https://lead-widget.gtrsuite.io/v2/widget.min.umd.js"
    );
    GTRLeadWidgetScript.setAttribute(
      "id",
      "GTRLeadWidgetScript"
    );
    document.head.appendChild(GTRLeadWidgetScript);
  },
  watch: {
    chairSimilar(val) {
      this.similar = val;
    },
    getItems() {
      this.updatePreviewContent();
    },
    getItemsCount() {
      this.updatePreviewContent();
    },
    isShowGTRSuite() {
      this.skus = Array.prototype.map.call(this.getItems, (item) =>
        item.description.replace(/\s\s+/g, ' ')
      ).join(",");

      // this.additionalData = [];
      this.additionalData = Array.prototype.map.call(this.getItems, (item) => {
        let obj = {};
        obj['SKU'] = item.description.replace(/\s\s+/g, ' ');
        obj['Note'] = item.note;
        item.options.map(({label, V}) => {
          obj[this.getLabel(label)] = V
        });
        // this.additionalData.push(obj);
        return JSON.stringify(obj);
      }).join(",");
      this.additionalData = `[${this.additionalData}]`;
      console.log(this.additionalData);
    },
    order() {
      this.updatePreviewContent();
    },
    productLine() {
      // clear details panel at nav clicking event
      this.options = [];
    },
  },
  methods: {
    /**
     * Add an order item to cart store, call order image generation method and reset all selects.
     */
    async add() {
      this.showChairModal = !!this.isDialog;

      // Remove the edit item on cart
      if (this.isSaveBtn) {
        this.removeFromCart(this.editItemIndex);
      }

      const description = this.description(1)
        + this.description(2)
        + this.description(3);

      // Add the current item to store's first place
      await this.$store.dispatch('addToCart', {
        order_id: uuidv4(),
        base_id: this.options[0]['ID'],
        description,
        note: this.note,
        options: this.options,
        product_line: this.productLine,
        qty: this.qty
      });

      // Save order image into local storage.
      await this.$root.$emit('saveOrderImage', false, '', description);
      await this.updatePreviewContent();

      // Clear details panel
      if (!this.showChairModal) {
        this.options = [];
        // Hide order button
        await this.$emit('hideOrderBtn');
      }
    },
    /**
     * Add a similar chair to cart store.
     *
     * @return {Promise<void>}
     */
    async addSimilar() {
      const description = this.similarDescription(1)
        + this.similarDescription(2)
        + this.similarDescription(3);

      // Add the current item to store's first place.
      await this.$store.dispatch('addToCart', {
        order_id: uuidv4(),
        base_id: this.similar[0]['ID'],
        description,
        options: this.similar,
        product_line: this.productLine,
        qty: this.similarQty
      });

      // Save order image into local storage.
      await this.$root.$emit('saveOrderImage', true, this.chairType, description);
      await this.updatePreviewContent();

      // Close the similar-chair modal after adding into items list.
      this.$emit('closeDialog');

      this.showChairModal = false;

      // Clear details panel
      this.options = [];
    },
    prepareOrderImagesList() {
      this.orderImages = [];
      this.getItems.map(async (item) => {
        await this.toDataURL(item.image, (blob) => {
          this.orderImages.push({
            url: item.image,
            description: item.options[0].V + ` (${item.description.replace(/ +(?= )/g, ' ')})`,
            data: blob,
          });
        })
      });
    },
    changeQty(itemOffset, qty) {
      this.$store.dispatch('changeItemQty', {
        id: itemOffset,
        qty: qty
      });
      this.showCollapse = true;
      this.showCollapse = false;
      this.updatePreviewContent();
    },
    closeChairModal() {
      this.$emit('closeDialog');
      this.showChairModal = false;
    },
    /**
     * Generate description parts from DF and DFP.
     *
     * @param set
     * @return {string}
     */
    description(set = 1) {
      let description = '';
      for (
        let i = 0, setOffset = (set - 1) * 30;
        i < this.options.length;
        i++
      ) {
        let offset = this.options[i]['DFP'] !== undefined ? parseInt(this.options[i]['DFP']) : 0;
        if (
          this.options[i].DF === undefined ||
          offset <= setOffset ||
          offset > setOffset + 30
        ) {
          continue;
        }

        if (description.length < offset) {
          description = description.padEnd(offset - 1, ' ');
        }

        if (this.options[i].DF === '-'
          && (this.options[i].V === 'No Finish' || this.options[i].V === 'No Option')
        ) {
          this.options[i].DF = '';
        }

        if (this.options[0].DF === "SQR6214" && this.options[i].V === "No Upholstery") {
          this.options[0].DF = "SQR6204";
        }

        // add PART_FRAG instead of DESC_FRAG at first
        // if (i === 0) {
        //   description = this.options[i]['PF'];
        // } else {
        //   description = description.substr(0, offset) + this.options[i].DF +
        //     description.substring(offset + this.options[i].DF.length - 1);
        // }

        description = description.substr(0, offset - 1) + this.options[i].DF +
          description.substring(offset + this.options[i].DF.length - 1);
      }
      return description.replace(/\s|-/g, '') === '' ? '' : description;
    },
    /**
     * Edits the selected item on cart
     *
     * @param itemIndex
     */
    async editFromCart(itemIndex) {
      const product_line = this.getItems[itemIndex].product_line;
      this.editItemIndex = itemIndex;

      if (product_line === 'bedroom') {
        collections.bedroom.map(async (collection, index) => {
          if (collection.value === product_line) {
            await this.$emit('actionSetCollection', 'bedroom');
            await this.$emit('editMode', index, itemIndex);
          }
        });
      } else {
        collections.dining.map(async (collection, index) => {
          if (collection.value === product_line) {
            await this.$emit('actionSetCollection', 'dining');
            await this.$emit('editMode', index, itemIndex);
          }
        });
      }

      setTimeout(() => {
        this.options = this.getItems[itemIndex].options;
      }, 1000)
    },
    getFinishLabel() {
      return this.productLine.charAt(0).toUpperCase() + this.productLine.slice(1) + ' Finish';
    },
    getLabel(str) {
      return str.charAt(7).toUpperCase() + str.slice(8);
    },
    onEmail() {
      if (!this.toEmailAddress || this.validEmail(this.toEmailAddress) === false) {
        this.$notify({
          group: 'product',
          type: 'error',
          text: 'Please enter an valid email address.',
          duration: 5000,
        });
        return;
      }

      if (this.getItems.length === 0) {
        this.$notify({
          group: 'product',
          type: 'error',
          text: 'Please add an order and try again.',
          duration: 5000,
        });
        return;
      }

      this.isSendingMail = true;
      const postData = {
        items: this.getItems,
        customer_name: this.order.customerName,
        to_email_address: this.toEmailAddress,
      };

      axios
        .post(`${process.env.VUE_APP_API_HOST}/api/public/sendmail`, postData)
        .then((res) => {
          this.isSendingMail = false;
          this.showEmailModal = false;
          this.$notify({
            group: 'product',
            type: 'success',
            text: res.data.message,
            duration: 5000,
          });
        })
        .catch(err => {
          this.isSendingMail = false;
          this.$notify({
            group: 'product',
            type: 'error',
            text: err.response.data.message,
            duration: 5000,
          });
        });
    },
    onModalCollapse() {
      this.modalCollapse = !this.modalCollapse;
    },
    onPdfDownload() {
      try {
        const doc = new jsPDF({
          orientation: 'p',
          unit: 'pt',
          format: 'letter'
        });
        const head = [
          {
            preview: 'PREVIEW',
            quantity: 'QTY',
            description: 'DESCRIPTION',
          },
        ];
        const disclaimer = "While the information displayed on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.\n" +
          "We reserve the right to change this pricing at any given time, of which you will be promptly updated.\n" +
          "If you want to make sure you are up to date with the latest changes, we advise you frequently visit the site.";
        let raw = [];
        let rowSpan = [];
        let body = [];
        let rowIndex = 0;

        this.getItems.map((item) => {
          let rowCount = 0;
          const description = item.options[0].V
            + ` (${item.description.replace(/ +(?= )/g, ' ')})`;

          raw.push({
            preview: '',
            quantity: item.qty,
            description,
          });

          item.options.map((option, i) => {
            if (i > 0 && option.V !== 'No Finish') {
              raw.push({
                preview: '',
                quantity: '',
                description: option.V,
              });
              rowCount += 1;
            }
          });

          if (item.note) {
            raw.push({
              preview: '',
              quantity: '',
              description: `Note: ${item.note}`,
            });
            rowCount += 1;
          }

          rowSpan.push(rowCount + 1);
        });

        for (let i = 0; i < raw.length; i++) {
          let row = [];
          for (let key in raw[i]) {
            if (['preview', 'quantity'].includes(key)) {
              if (raw[i]['quantity'] !== '') {
                row.push({
                  rowSpan: rowSpan[rowIndex],
                  content: raw[i][key],
                  styles: {
                    halign: 'center',
                    fontStyle: 'bold',
                    cellWidth: key === 'preview' ? 150 : 'auto',
                  },
                });

                if (key === 'quantity') {
                  rowIndex += 1;
                }
              }
            } else {
              const isParentDesc = raw[i]['quantity'] !== '' && key === 'description';
              row.push({
                content: raw[i][key],
                styles: {fontStyle: isParentDesc ? 'bold' : 'normal'},
              });
            }
          }
          body.push(row)
        }

        doc.autoTable({
          head: head,
          body: body,
          foot: [
            [{
              content: disclaimer,
              colSpan: 3,
              styles: {
                cellPadding: 10,
                fillColor: false,
                fontSize: 7.5,
                fontStyle: 'normal',
                halign: 'left',
                minCellHeight: 50,
                textColor: 20,
              },
            }],
          ],
          theme: 'striped',
          styles: {
            fillColor: [51, 51, 51],
            lineColor: 240,
            lineWidth: 1,
            customer_name: this.order.customerName,
            images: this.orderImages,
          },
          headStyles: {halign: 'center'},
          footStyles: {halign: 'center'},
          columnStyles: {
            preview: {fillColor: false, halign: 'center'},
            quantity: {fillColor: false, halign: 'center'},
            description: {fillColor: false},
          },
          margin: {top: 140},
          showFoot: 'lastPage',
          didDrawCell(data) {
            if (data.section === 'body' && data.column.index === 0) {
              const description = data.row.raw[2].content;
              data.table.styles.styles.images.map((image) => {
                if (image.description === description) {
                  const diff = (data.cell.height - data.cell.width) / 2;
                  doc.addImage(
                    image.data,
                    'JPEG',
                    data.cell.x,
                    data.cell.y + diff,
                    data.cell.width,
                    data.cell.width,
                  );
                }
              });
            }
          },
          didDrawPage(data) {
            const isFirstPage = data.pageNumber === 1;
            data.settings.margin.top = 40;

            // Add header section at only first page.
            if (isFirstPage) {
              doc.addImage(PrintLogo.base64Image, 'JPEG', data.settings.margin.left, 15, 100, 75);
              doc.setFont('Helvetica', '', 'bold');
              doc.setFontSize(12);
              let height = 40;
              const pageY = doc.internal.pageSize.width - data.settings.margin.right;
              doc.setDrawColor(0, 0, 0);
              doc.line(300, height, pageY, height);
              if (data.table.styles.styles.customer_name) {
                height = height + 10;
                doc.setFont('Helvetica', '', 'bold');
                doc.setFontSize(8);
                doc.text(data.table.styles.styles.customer_name, pageY, height, 'right');
              }
            }
          },
        });

        doc.save(`order-details-${(timeNow.getMonth() + 1)
        + '-' + timeNow.getDate()
        + '-' + timeNow.getFullYear()}.pdf`);

        axios
          .post(`${process.env.VUE_APP_API_HOST}/api/public/download-pdf`, {items: this.getItems})
          .then(res => {
            this.$notify({
              group: 'product',
              type: 'success',
              text: res.data.message,
              duration: 5000,
            });
            this.isShowOrderModal = false;
          });

      } catch (e) {
        console.log('Details > onPdfDownload | ', e);
      }
    },
    onPrint() {
      this.printContent = document.getElementById('preview-print').innerHTML;
      const newWindow = window.open('', '', 'width=100, height=100');
      const doc = newWindow.document.open();
      const pageContent =
        '<!DOCTYPE html>' +
        '<html lang="en">' +
        '<head>' +
        '<meta charset="utf-8" />' +
        `<title>${this.$t('message.orderDetails')}</title>` +
        '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
        '</head>' +
        '<body>' +
        this.printContent +
        '</body></html>';
      doc.write(pageContent);
      doc.close();
      newWindow.moveTo(0, 0);
      newWindow.resizeTo(screen.width, screen.height);

      axios
        .post(`${process.env.VUE_APP_API_HOST}/api/public/print-orders`, {items: this.getItems})
        .then(res => {
          this.$notify({
            group: 'product',
            type: 'success',
            text: res.data.message,
            duration: 5000,
          });
          this.isShowOrderModal = false;
        });

      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 250);
    },
    /**
     *
     * @param index
     */
    onToggleCollapse(index) {
      this.collapseIndex = index;
      if (this.collapseIndex !== index) {
        this.showCollapse = true;
      } else {
        this.showCollapse = !this.showCollapse;
      }
    },
    orderEnable(state, similar) {
      this.qty = 1;
      this.orderEnabled = state;
      this.similar = similar;
    },
    /**
     * Remove the selected item on cart
     *
     * @param itemIndex
     */
    removeFromCart(itemIndex) {
      this.$store.dispatch('removeFromCart', itemIndex)
        .then(() => {});
    },
    /**
     * Remove all items on cart
     *
     */
    removeAllCart() {
      this.$store.dispatch('removeAllFromCart')
        .then(() => {});
    },
    setOptions(data) {
      this.options = [];

      data.forEach((element) => {
        if (element === null) {
          return;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (element.hasOwnProperty('extra')) {
          this.options = this.options.concat(element.extra);
          delete element.extra;
        }

        this.options.push(element);
      });
    },
    showOrderModal(actionKey) {
      this.isShowOrderModal = true;
      this.isShowOrderAction = actionKey;
      this.$nextTick(() => {
        this.prepareOrderImagesList();
      });
    },
    similarDescription(set = 1) {
      let description = '';

      for (
        let i = 0, setOffset = (set - 1) * 30;
        i < this.similar.length;
        i++
      ) {
        let offset =
          this.similar[i]['DFP'] !== undefined ? parseInt(this.similar[i]['DFP']) : 0;
        if (
          this.similar[i].DF === undefined ||
          offset <= setOffset ||
          offset > setOffset + 30
        ) {
          continue;
        }

        if (description.length < offset) {
          description = description.padEnd(offset - 1, ' ');
        }

        if (this.similar[i].DF === '-' && this.similar[i].V === 'No Finish') {
          this.similar[i].DF = '';
        }

        description = description.substr(0, offset - 1) + this.similar[i].DF +
          description.substring(offset + this.similar[i].DF.length - 1);
      }
      return description.replace(/\s|-/g, '') === '' ? '' : description;
    },
    /**
     * Create a bug report on selected product with dropdown options.
     *
     */
    submitBug() {
      const params = [].concat(
        this.description(1),
        this.internals,
        this.product,
        this.options.map(opt => opt.V)
      );
      // @ts-ignore
      window.open(`${this.API_URL}?data=${encodeURIComponent(params)}`);
    },
    /**
     * Read base64 image blob from url.
     * @param url
     * @param callback
     */
    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    validEmail(emails) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let flag = true;
      emails.forEach(email => {
        if (!pattern.test(email.text)) {
          flag = false;
        }
      })
      return flag;
    },
    updatePreviewContent() {
      this.$nextTick(() => {
        this.printContent = document.getElementById('preview-print').innerHTML;
      });
    },
  },
};

</script>
<style lang="scss" scoped>
#preview-print {
  visibility: hidden;
  display: none;
}

#preview-print .print-logo {
  width: 3.25in;
  height: auto;
  -webkit-print-color-adjust: exact;
}

#preview-print body, html {
  width: 8.5in;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
}

@media print {
  body, html {
    width: 8.5in;
    font-size: 16px;
    font-family: 'Helvetica', sans-serif;
  }

  #preview-print {
    visibility: visible;
    display: block;
    page-break-after: always;
  }

  #preview-print th {
    /*text-align: right;*/
  }

  #preview-print table.items,
  #preview-print table.total {
    border: 1px solid rgb(240, 240, 240);
    border-collapse: collapse;
  }

  #preview-print table.items th,
  #preview-print table.items td {
    border: 1px solid #ccc;
    border-collapse: collapse;
  }

  table.items tbody,
  table.items tbody tr {
    page-break-inside: avoid;
  }

  .items tr.product-header td {
    background-color: #000;
    color: #fff;
  }

  .items td {
    padding: 3px 6px;
  }

  .items tr.single-item td {
    font-size: 12px;
    padding: 2px 6px;
    color: #000;
  }

  .total tr.total-header td {
    background-color: #000;
    color: #fff;
  }

  .total td {
    padding: 10px 6px;
  }

  table.logo-header {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    margin-bottom: 15px;
  }

  table.logo-header td {
    border: 0;
  }

  table.logo-header h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .print-logo {
    width: 3.25in;
    height: auto;
    -webkit-print-color-adjust: exact;
  }

  .note {
    font-size: 14px;
    /*text-align: right;*/
    display: block;
    width: 100%;
  }

  img {
    display: inline;
    visibility: visible;
  }
}

.gti-suite-modal {
  //display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 515px;
  }

  /* The Close Button */
  .close {
    color: #aaaaaa;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 100;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}
.dp-instructions {
  line-height: 1.4;
  font-size: 13px;
  border: 0;
  padding: 0 15px;
  border-radius: 0;
  font-weight: bold;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .step {
    font-size: 11px;
    color: #999;
    display: block;
    margin-bottom: 8px;
  }
  .single-step {
    border-right: 2px solid #eee;
    border-bottom: 2px solid #eee;
    padding-bottom: 13px;
  }
  .single-step:last-of-type {
    border-right: 0;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1199px) {
  .dp-instructions {
    .single-step {
      border-right: 0;
      border-bottom: 0;
    }
    .single-step:last-of-type {
      border-right: 0;
      border-bottom: 0;
    }
  }
}
</style>
