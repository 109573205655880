// Clone 4 table legs from one model, and calculate each one's position and rotation.
export const TableLegDeflects = {
  // Square/Rectangle
  'A': {
    x: 0.195,
    y: 0,
    z: 0.215,
    r: 0,
  },
  // Oval/Round
  'B': {
    x: 0.600,
    y: 0,
    z: 0.440,
    r: 0,
  },
  // Queen Anne
  'D': {
    x: 0.205,
    y: 0,
    z: 0.235,
    r: 0,
  },
  // Polo
  'E': {
    x: 0.085,
    y: 0,
    z: 0.065,
    r: 0,
  },
  // Concave
  'F': {
    x: 0.205,
    y: 0,
    z: 0.275,
    r: 0,
  },
  // Boat
  'G': {
    x: 0.205,
    y: 0,
    z: 0.215,
    r: 0,
  },
  // Clipped Corner
  'H': {
    x: 0.208,
    y: 0,
    z: 0.235,
    r: 0,
  },
  // Cosmo
  'K': {
    x: 0.045,
    y: 0,
    z: 0.025,
    r: 0,
  },
  // Neo
  'L': {
    x: 0.435,
    y: 0,
    z: 0.225,
    r: 0,
  },
  // Naturale
  'N': {
    x: 0.131,
    y: 0,
    z: 0.124,
    r: 0,
  },
  // LIVE EDGE
  'T': {
    x: 0.131,
    y: 0,
    z: 0.124,
    r: 0,
  },
  // Default
  'default': {
    x: 0.0,
    y: 0,
    z: 0.0,
    r: 0,
  },
};
