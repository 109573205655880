//messages.js
export default {
  threeView: '3D VIEW',
  backup: 'Backup',
  bedroomCollection: 'BEDROOM',
  changePassword: 'Change Password',
  customerName: 'Customer Name',
  customersList: 'Customers List',
  todayOrders: 'Today Orders',
  dashboard: 'Dashboard',
  deleteAll: 'Delete All',
  designpal: 'DesignPal',
  detailPanelTitle: 'Item You Are Currently Building',
  diningCollection: 'DINING',
  disclaimer: `While the information displayed on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.<br>
               We reserve the right to change this pricing at any given time, of which you will be promptly updated. <br>
               If you want to make sure you are up to date with the latest changes, we advise you frequently visit the site.`,
  emailQuote: 'Email Quote',
  forgotPassword: 'Forgot Password',
  help: 'HELP',
  homeOfficeCollection: 'HOME OFFICE',
  login: 'Login',
  maintenance: 'Maintenance',
  occasionalCollection: 'OCCASIONAL',
  orderDetails: 'Order Details',
  orders: 'Orders',
  orderTotal: 'ORDER TOTAL:',
  pages: 'Pages',
  pdfQuote: 'PDF Quote',
  poNumber: 'PO Number',
  pricing: 'Pricing',
  printQuote: 'Print Quote',
  resetPassword: "Reset Password",
  schedule: 'Schedule',
  sendTo: 'Send To',
  sendToLabel: `To send to multiple email accounts press "Tab Key" or separate email addresses with a "Comma" (,)`,
  settings: 'Settings',
  signUp: 'Sign Up',
  submitBug: 'Submit Bug',
  submitOrder: 'Submit Order',
  surcharge: 'Surcharge',
  surchargeLabel: 'SURCHARGE:',
  userManagement: 'User Management',
  weeklyEmails: 'Order Email (Week)',
  weeklyDealers: 'Dealers Logged (Week)',
  weeklyPrints: 'Order Print (Week)',
}
