<template>
  <div class="dp-options-picker">
    <div
      v-show="!!items.length && isLoaded"
      class="single-dropdown"
      :disabled="disabled"
    >
      <label>{{ label }}</label>
      <div @click="scrollToBottom">
        <vue-select
          v-model="selected"
          :options="items"
          :clearable="false"
          :placeholder="label"
          :select-on-tab="true"
          label="V"
          @input="chosen"
        >
          <template v-slot:option="option">
            <span v-html="optionBreakLine(option.V)" />
          </template>
        </vue-select>
      </div>
    </div>
    <span
      v-if="hint"
      class="custom-hint"
    >{{ hint }}</span>
  </div>
</template>
<script>
export default {
  name: 'Selector',
  props: {
    id: {
      type: String,
      default() {
        return '';
      }
    },
    isEditMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    label: {
      type: String,
      default() {
        return '';
      }
    },
    isLoaded: {
      type: Boolean,
      default() {
        return true;
      }
    },
    /**
     * The product's type
     * `tables, chairs, barstool, benches, case`
     */
    productLine: {
      type: String,
      default() {
        return '';
      }
    },
    values: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      select: {},
      items: [],
      disabled: false,
      selected: '',
      scrollTop: 0,
    }
  },
  computed: {
    hint: function() {
      return this.select.V !== undefined &&
      this.select.V.toLowerCase() === 'custom'
        ? 'Custom options are not available in the online builder. Please call our offices to complete the order.'
        : '';
    }
  },
  watch: {
    items() {
      this.selected = '';
      this.select = {};
    },
    values(items) {
      this.items = items;
    }
  },
  created() {
    this.items = (this.productLine === 'table' && this.id === '00000000')
      ? this.tableDataSort(this.values)
      : this.values;
  },
  mounted() {
    this.$nextTick(() => {
      const counter = document.getElementsByClassName('v-select').length;
      const dropdown = document.getElementsByClassName('v-select')[counter - 1];
      const rect = dropdown.getBoundingClientRect();
      if (dropdown) {
        if (rect.top + document.documentElement.scrollTop > window.innerHeight) {
          document.documentElement.scrollTop = rect.top + document.documentElement.scrollTop + 40 - window.innerHeight;
        }
      }
    });
  },
  methods: {
    /**
     * @param {Object} val
     */
    chosen(val) {
      this.select = val;
      this.$emit(
        'next',
        this.id,
        this.select.V.toLowerCase() !== 'custom' ? this.select.ID : '_CUSTOM_',
        this.select.V,
        this.select.DF,
        this.label,
        false,
      );
    },
    /**
     * @param {string} option
     */
    optionBreakLine(option) {
      return option.replace(/([\r\n])/g, '<br />');
    },
    /**
     *
     */
    scrollToBottom() {
      this.$nextTick(() => {
        const dropdown = document.getElementsByClassName('vs__dropdown-menu')[0];
        if (dropdown) {
          this.scrollTop = document.documentElement.scrollTop;
          // get dropdown position
          const rect = dropdown.getBoundingClientRect();
          // page scroll-height after selecting the dropdown
          const currentScrollHeight = this.scrollTop + rect.top + dropdown['offsetHeight'] + 40;

          if (rect.top + dropdown['offsetHeight'] > window.innerHeight) {
            if (document.documentElement.scrollHeight < currentScrollHeight) {
              dropdown.classList.remove('drop-down');
              dropdown.classList.add('drop-up');
            } else {
              document.documentElement.scrollTop = currentScrollHeight - window.innerHeight;
            }
          }
        }
      });
    },
    /**
     * @param {boolean} state
     */
    setDisabled(state) {
      this.disabled = state;
    },
    /**
     * Sorts the items array such that items with non-numeric first characters in the 'V' property come first.
     * @param {array} items
     * @returns {array} Sorted items
     */
    tableDataSort(items) {
      const nonNumericTables = items.filter(item => isNaN(Number(item.V.charAt(0))));
      const numericTables = items.filter(item => !isNaN(Number(item.V.charAt(0))));
      return [...nonNumericTables, ...numericTables];
    },
  }
};
</script>


