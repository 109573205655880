import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme:{
    theme: {
      light: {
        primary: '#5D92F4',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF3739',
        info: '#00D0BD',
        success: '#00D014',
        warning: '#FFB70F'
      }
    },
    options: {
      customProperties: true,
    },
  },
})
