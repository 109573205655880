import Vue from 'vue'
import Vuex from 'vuex'

// modules
import cart from './modules/cart';
import models from './modules/models';
import settings from './modules/settings';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    cart,
    models,
    settings,
  },
});
