/**
 * Furniture Models Module
 */
import axios from "axios";

const state = {
  models: [],
};

// getters
const getters = {
  initialModels: state => {
    return state.models;
  },
};

// actions
const actions = {
  /**
   * Collect the furniture models as an initial list.
   */
  async getInitialModels(context) {
    await axios
      .get(`${process.env.VUE_APP_API_HOST}/api/public/initial-models`)
      .then(res => {
        context.commit('setInitialModelsData', res.data.models);
      });
  },
};

// mutations
const mutations = {
  setInitialModelsData(state, models) {
    state.models = models;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
